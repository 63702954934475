<template>
  <ClFormRow :label="label" :toolTipText="tooltip" name="awardsId" ref="selectedAward">
    <template #content>
      <multiselect
          v-model="selectedAward"
          :options="awardsOptions"
          :multiple="false"
          :hide-selected="true"
          :selectLabel="selectLabel"
          :block-keys="['Tab', 'Enter']"
          required
          label="name"
          track-by="id"
          class="custom-multi-select zq--multiselect"
      />
    </template>
  </ClFormRow>
</template>

<script>
import {formConfig} from "@/config";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ZqSelectAwards",
  props: {
    value: [Array, String],
    label: String,
    tooltip: String,
    css: [String, Object],
    multiple: { type: Boolean, default: false },
    searchable: { type: Boolean, default: true },
  },
  data() {
    return {
      selectLabel: formConfig.selectLabel,
      selectedAward: [],
    }
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    ...mapGetters('awards', ['awards']),
    awardsOptions() {
      let awardsOptions = [];

      if (this.awards) {
        this.awards.forEach(award => {
          awardsOptions.push({
            name: award.name,
            id: award.id
          })
        })
      }
      return awardsOptions;
    },
  },
  watch: {
    selectedAward: {
      deep: true,
      handler(val) {
        if (val && val.length) {
          this.$refs.selectedAward.$el.classList.remove('zq-invalid');
        }

        const awardId = this.selectedAward.map(item => item.id)
        this.$emit('input', awardId);
      }
    }
  },
  methods: {
    ...mapActions('awards', ['handleGetAwards']),
    initialize() {
      this.handleGetAwards({
        id: [],
        skip: 0,
        limit: 100,
      });
    },
  }
}
</script>

<style scoped>

</style>