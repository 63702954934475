<template>
  <ClFormRow :label="label" :toolTipText="toolTipText" name="triggers" ref="triggersSelect">
    <template #content>
      <multiselect
          v-model="selectedTriggers"
          :options="triggersListOptions"
          :multiple="true"
          :hide-selected="true"
          :selectLabel="selectLabel"
          :block-keys="['Tab', 'Enter']"
          required
          label="name"
          track-by="id"
          class="custom-multi-select zq--multiselect"
      />
    </template>
  </ClFormRow>
</template>

<script>
import {formConfig} from "@/config";
import {mapActions} from "vuex";

export default {
  name: "ZqSelectWebhooksTriggers",
  props: {
    value: [Array, String],
    label: String,
    toolTipText: String,
    css: [String, Object],
    multiple: { type: Boolean, default: false },
    searchable: { type: Boolean, default: true },
  },
  data() {
    return {
      selectLabel: formConfig.selectLabel,
      triggersList: [],
      triggerNames: [],
      selectedTriggers: []
    }
  },
  created() {
    this.initialize();
  },
  computed: {
    triggersListOptions() {
      return this.triggersList
          ? this.triggersList.map(item => ({name: item.name, id: item.id}))
          : []
    }
  },
  watch: {
    selectedTriggers: {
      deep: true,
      handler: function (val) {
        if (val && val.length) {
          this.$refs.triggersSelect.$el.classList.remove('zq-invalid');
        }

        const triggersIds = this.selectedTriggers.map(item => item.id)
        this.$emit('input', triggersIds)
      },
    },
  },
  methods: {
    ...mapActions('webhooks', ['handleGetWebhooksTriggers']),
    async initialize() {
      this.triggersList = await this.handleGetWebhooksTriggers([], null, null);

      if (this.value) {
        this.selectedTriggers = this.triggersList
            .filter(item => this.value.includes(item.id))
            .map(item => ({name: item.name, id: item.id}));
      }
    },
  }
}
</script>

<style scoped>

</style>