<template>
  <ClFormRow :label="label" :toolTipText="tooltip" tooltipPosition="label" xl-content="8">
    <template #content>
      <div class="zq--metadata">
        <template v-for="(field, index) in meta">
          <CRow form :key="index">
            <CCol>
              <ClRowInput
                type="text"
                name="textValue"
                v-model="field.val"
                :disabled="disabled"
              />
            </CCol>
            <CCol class="zq--metadata--actions" v-show="!disabled">
              <CButton
                type="button"
                :color="setBtnColor('danger')"
                size="sm"
                class="zq--small-button"
                @click="deleteField(index)"
              >
                <CIcon name="cil-minus" />
              </CButton>
              <CButton
                type="button"
                :color="setBtnColor('success')"
                class="zq--small-button ml-2"
                size="sm"
                :disabled="!field.val"
                @click="addField(index)"
                v-if="meta.length - 1 === index"
              >
                <CIcon name="cil-plus" />
              </CButton>
            </CCol>
          </CRow>
        </template>
      </div>
    </template>
  </ClFormRow>
</template>

<script>
import ClRowInput from '@/components/forms/ClRowInput';
import { isEqual } from 'lodash';
export default {
  name: 'ZqTextArray',
  components: {
    ClRowInput,
  },
  props: {
    value: [Object, Array],
    disabled: Boolean,
    headers: Boolean,
    isValid: Boolean,
    invalidMessage: String,
    label: String,
    tooltip: String,
  },
  data() {
    return {
      meta: [],
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    meta: {
      deep: true,
      handler() {
        const arr = [];
        this.meta.forEach((x) => {
          arr.push(x.val)
        });
        this.$emit('input', arr.length ? arr : null);
      },
    },
    value: {
      deep: true,
      handler(n, o) {
        if (!isEqual(n, o)) {
          this.initialize();
        }
      },
    },
  },
  methods: {
    blankMeta() {
      this.meta = [{ val: '' }];
    },
    initialize() {
      if (this.value && Object.keys(this.value).length > 0) {
        const obj = [];
        Object.keys(this.value).forEach((x) => {
          obj.push({ key: x, val: this.value[x] });
        });
        this.meta = obj;
      } else {
        this.blankMeta();
      }
    },
    addField: function () {
      this.meta.push({ value: '' });
    },
    deleteField: function (index) {
      if (this.meta.length === 1) {
        this.blankMeta();
      }
      if (this.meta.length > 1) {
        this.meta.splice(index, 1);
      }
    },
    setBtnColor(color) {
      switch (this.theme) {
        case 'main': {
          return '';
        }
        case 'default': {
          return color;
        }
        default: {
          return '';
        }
      }
    },
  },
};
</script>
