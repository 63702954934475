<template>
  <ClFormRow :label="label" :toolTipText="tooltip" name="transformersId" ref="selectedTransformer">
    <template #content>
      <multiselect
        v-model="selectedTransformer"
        :options="transformerOptions"
        @search-change="onInput"
        :multiple="false"
        :hide-selected="true"
        :selectLabel="selectLabel"
        :block-keys="['Tab', 'Enter']"
        required
        label="name"
        track-by="id"
        class="custom-multi-select zq--multiselect"
        placeholder="Type here..."
      >
        <span slot="noOptions"></span>
      </multiselect>
      <img
        src="../../assets/icons/search.svg"
        width="16"
        height="16"
        alt="search"
        class="zq--multiselect-search-icon"
      />
    </template>
  </ClFormRow>
</template>

<script>
import { formConfig } from '@/config';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "ZqSelectTransformerId",
  props: {
    value: [Array, String],
    label: String,
    tooltip: String,
    css: [String, Object],
    multiple: { type: Boolean, default: false },
    searchable: { type: Boolean, default: true },
  },
  data() {
    return {
      selectLabel: formConfig.selectLabel,
      selectedTransformer: null,
      transformerOptions: [],
      initOptions: null,
    }
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    ...mapGetters('transformers', ['transformers']),
  },
  watch: {
    selectedTransformer: {
      deep: true,
      handler(val) {
        if (val && val.length) {
          this.$refs.selectedTransformer.$el.classList.remove('zq-invalid');
        }

        this.$emit('input', this.selectedTransformer.id);
      }
    }
  },
  methods: {
    ...mapActions('transformers', ['handleGetTransformersByQuery']),
    async initialize() {
      this.transformerOptions = [];

      if (this.value) {
        const transformer = await this.handleGetTransformersByQuery({
          queryRequest: {
            must: [
              {
                queryField: 'id',
                queryValues: [this.value]
              }
            ]
          }
        });

        this.selectedTransformer = {name: transformer[0].name, id: transformer[0].id};
      } else {
        this.initOptions = await this.handleGetTransformersByQuery({
          queryRequest: {
            sortBy: [
              {
                queryField: 'name',
                order: 'Asc',
              },
            ],
            limit: 20
          }
        });

        this.transformerOptions = this.initOptions;
      }
    },
    async onInput(val) {
      if (val.length > 1) {
        const transformers = await this.handleGetTransformersByQuery({
          queryRequest: {
            multiFields: [
              {
                queryFields: ['name'],
                queryValue: val
              }
            ]
          }
        });

        this.transformerOptions = transformers.map(transformer => ({
          name: transformer.name,
          id: transformer.id
        }));
      } else {
        this.transformerOptions = this.initOptions ?? [];
      }
    }
  }
}
</script>

<style scoped>

</style>
