<template>
  <section>
    <CreateHeader
      v-if="isCreateHeader"
      :loading="loading"
      :descriptions="page.info"
      :title="page.title"
      :submit-title="page.mode ? page.mode : 'create'"
    />
    <div class="form-content content">
      <h3 class="mb-3 bread-content" v-if="isSettingsStep">{{ stepNumber }}. {{ pageTitle }}</h3>
      <ClPageToolbar :isLeftSide='true' @toggleEditorToolbar="handleToggleEditorToolbar"  />
      <section v-for="(form, index) of formList" :key="form.key + '_' + index">
        <SwitchRow
          v-if="form.type.toLowerCase() === $formType.SWITCH"
          md-label="2"
          lg-label="2"
          sm-label="2"
          :label="getLabel(form)"
          :toolTipText="form.tooltip"
          :valueProp="form.value"
          :disabled="form.disabled"
          :hidden="isHideDisabled && form.disabled"
          @swipeHandler="form.value = $event === 'right'"
          @updateHandler="form.value = $event"
        />

        <CIInput
          v-if="form.type.toLowerCase() === $formType.CREATED"
          :label="getLabel(form)"
          :tooltip="form.tooltip"
          :value="dateFormate(form.value)"
          :placeholder="form.placeholder"
          :disabled="true"
          :hidden="isHideDisabled && form.disabled"
          :required="form.required"
          :name="form.key"
        />

        <CIInput
          v-if="form.type.toLowerCase() === $formType.TEXT"
          :label="getLabel(form)"
          :tooltip="form.tooltip"
          v-model="form.value"
          :placeholder="form.placeholder"
          :disabled="form.disabled"
          :hidden="isHideDisabled && form.disabled"
          :required="form.required"
          :name="form.key"
        />

        <CIInput
          v-if="form.type.toLowerCase() === $formType.ID"
          :label="getLabel(form)"
          :tooltip="form.tooltip"
          v-model="form.value"
          :placeholder="form.placeholder"
          :disabled="form.disabled"
          :hidden="isHideDisabled && form.disabled"
          :required="form.required"
          :name="form.key"
        />

        <CIInput
          v-if="form.type.toLowerCase() === $formType.NUMBER"
          type="number"
          min="0"
          :label="getLabel(form)"
          :tooltip="form.tooltip"
          v-model="form.value"
          :placeholder="form.placeholder"
          :disabled="form.disabled"
          :hidden="isHideDisabled && form.disabled"
          :required="form.required"
          :name="form.key"
        />

        <CINameKeyField
          v-if="form.type.toLowerCase() === $formType.NAMEKEY"
          :tooltip="page.info"
          v-model="form.value"
          :placeholder="form.placeholder"
          :disabled="form.disabled"
          :hidden="isHideDisabled && form.disabled"
          :showKey="form.showKey ? form.showKey : false"
          :setIsManualKey="setIsManualKey"
          @setIsManualKey="setIsManualKey"
          :isManualKey="isManualKey"
          :setIsEditedKey="setIsEditedKey"
          @setIsEditedKey="setIsEditedKey"
          :isEditedKey="isEditedKey"
        />

        <CITextArea
          v-if="form.type.toLowerCase() === $formType.TEXTAREA"
          :label="getLabel(form)"
          :tooltip="form.tooltip"
          v-model="form.value"
          :disabled="form.disabled"
          :hidden="isHideDisabled && form.disabled"
          :placeholder="form.placeholder"
          :name="form.key"
          :isToolbar="isToolbar"
        />

        <CIIconField
          v-if="form.type.toLowerCase() === $formType.ICON"
          v-model="form.value"
          :label="getLabel(form)"
          :descriptions="page.info"
          :iconKey="form.key"
        />

        <CITagField
          v-if="form.type.toLowerCase() === $formType.TAG"
          :type="form.model"
          v-model="form.value"
          :label="getLabel(form)"
          :tooltip="form.tooltip"
          :disabled="form.disabled"
          :hidden="isHideDisabled && form.disabled"
        />

        <CICustomFields
          v-if="form.type.toLowerCase() === $formType.CUSTOM"
          :type="form.model"
          :val="form.value"
          v-model="form.value"
          :hidden="isHideDisabled && form.disabled"
        />

        <CISelectEntites
          v-if="form.type.toLowerCase() === $formType.ENTITIES"
          :label="getLabel(form)"
          :tooltip="form.tooltip"
          :disabled="form.disabled"
          :hidden="isHideDisabled && form.disabled"
          :selectLabel="form.label"
          :multiple="false"
          :searchable="true"
          :css="selectAppliesErrorClass"
          v-model="form.value"
        />

        <CISelectLanguage
          v-if="form.type.toLowerCase() === $formType.LANGUAGES"
          :label="getLabel(form)"
          :tooltip="page.info.name"
          :disabled="form.disabled"
          :hidden="isHideDisabled && form.disabled"
          :selectLabel="form.label"
          v-model="form.value"
        />

        <CISelectTranformer
          v-if="form.type.toLowerCase() === $formType.TRANFORMER"
          :label="getLabel(form)"
          :tooltip="form.tooltip"
          :disabled="form.disabled"
          :hidden="isHideDisabled && form.disabled"
          :selectLabel="form.label"
          v-model="form.value"
        />

        <ZqSelectMembers
          v-if="form.type.toLowerCase() === $formType.MEMBERIDS"
          :label="getLabel(form)"
          :tooltip="form.tooltip"
          :disabled="form.disabled"
          :hidden="isHideDisabled && form.disabled"
          :selectLabel="form.label"
          :required="form.required"
          :isMultiple="form.multiple"
          :fomKey="form.key"
          :isCreateMember="form.isCreate ? form.isCreate : false"
          :isUpdateMember="form.isUpdate ? form.isUpdate : false"
          v-model="form.value"
        />

        <ZqSelectEntityRefIds
          v-if="form.type.toLowerCase() === $formType.ENTITYREFIDS"
          :label="getLabel(form)"
          :tooltip="form.tooltip"
          :disabled="form.disabled"
          :hidden="isHideDisabled && form.disabled"
          :selectLabel="form.label"
          :required="form.required"
          :isMultiple="form.multiple"
          v-model="form.value"
        />

        <ZqHostingOptionsFields
          v-if="form.type.toLowerCase() === $formType.HOSTINGOPTIONS"
          v-model="form.value"
          :disabled="form.disabled"
        />

        <ZqSelectAwards
          v-if="form.type.toLowerCase() === $formType.AWARDSIDS"
          :label="getLabel(form)"
          :tooltip="form.tooltip"
          :disabled="form.disabled"
          :hidden="isHideDisabled && form.disabled"
          :selectLabel="form.label"
          v-model="form.value"
        />

        <ZqSelectTransformerId
          v-if="form.type.toLowerCase() === $formType.TRANSFORMERIDS"
          :label="getLabel(form)"
          :tooltip="form.tooltip"
          :disabled="form.disabled"
          :hidden="isHideDisabled && form.disabled"
          :selectLabel="form.label"
          :required="form.required"
          v-model="form.value"
        />

        <ZqDatePicker
          v-if="form.type.toLowerCase() === $formType.DATETIME"
          :label="getLabel(form)"
          :tooltip="form.tooltip"
          v-model="form.value"
          :placeholder="form.placeholder"
          :disabled="form.disabled"
          :required="form.required"
          :name="form.key"
          :dateProp="form.value"
        />

        <CIMetaDataField
          v-if="form.type.toLowerCase() === $formType.META"
          v-model="form.value"
          :label="getLabel(form)"
          :tooltip="form.tooltip"
          :disabled="form.disabled"
          :hidden="isHideDisabled && form.disabled"
          :placeholder="form.placeholder"
        />

        <ZqTextArray
          v-if="form.type.toLowerCase() === $formType.TEXTARRAY"
          v-model="form.value"
          :label="getLabel(form)"
          :tooltip="form.tooltip"
          :disabled="form.disabled"
          :hidden="isHideDisabled && form.disabled"
          :placeholder="form.placeholder"
        />

        <ZqActionTypeAdjustmentFactorsField
          v-if="form.type.toLowerCase() === $formType.ACTIONTYPEADJUSTMENTFACTORS"
          v-model="form.value"
          :label="getLabel(form)"
          :tooltip="form.tooltip"
          :disabled="form.disabled"
          :hidden="isHideDisabled && form.disabled"
          :placeholder="form.placeholder"
        />

        <ZqSelectField
          v-if="form.type.toLowerCase() === $formType.SELECT"
          v-model="form.value"
          :name="form.key"
          :tooltip="form.tooltip"
          :label="getLabel(form)"
          :toolTipText="form.tooltip"
          :placeholder="form.placeholder"
          :disabled="form.disabled"
          :required="form.required"
          :options="form.options"
          :selectLabel="form.placeholder"
          :multiple="multipleFields.includes(form.key)"
          :taggable="taggableFields.includes(form.key)"
        />

        <ZqSelectRewardTypeId
          v-if="form.type.toLowerCase() === $formType.REWARDTYPEIDS"
          v-model="form.value"
          :label="getLabel(form)"
          :toolTipText="form.tooltip"
          :options="form.options"
          :disabled="form.disabled"
          :required="form.required"
          :placeholder="form.placeholder"
        />

        <ZqSelectWebhooksTriggers
          v-if="form.type.toLowerCase() === $formType.TRIGGERS"
          v-model="form.value"
          :label="getLabel(form)"
          :toolTipText="page.info.triggers"
          :options="form.options"
          :disabled="form.disabled"
          :required="form.required"
          :placeholder="form.placeholder"
          :selectLabel="form.placeholder"
        />

        <ZqSelectRewardEntityType
          v-if="form.type.toLowerCase() === $formType.REWARDENTITYTYPE"
          v-model="form.value"
          :options="form.options"
          :label="getLabel(form)"
          :toolTipText="form.tooltip"
          :disabled="form.disabled"
          :required="form.required"
          :placeholder="form.placeholder"
          :selectLabel="form.placeholder"
        />

        <ZqSelectUnitOfMeasure
          v-if="form.type.toLowerCase() === $formType.UNITOFMEASURE"
          v-model="form.value"
          :options="form.options"
          :label="getLabel(form)"
          :toolTipText="page.info.unitOfMeasureType"
          :disabled="form.disabled"
          :required="form.required"
          :placeholder="form.placeholder"
          :selectLabel="form.placeholder"
        />

        <ZqInstantWinType
          v-if="form.type.toLowerCase() === $formType.INSTANTWINTYPE"
          v-model="form.value"
          :label="getLabel(form)"
          :toolTipText="form.tooltip"
        />

      </section>
    </div>
  </section>
</template>
<script>
import { isEqual, startCase } from 'lodash';
import { mapGetters } from 'vuex';
import CreateHeader from '@/shared/components/CreateHeader';
import SwitchRow from '@/shared/components/SwitchRow';
import ZqDatePicker from '@/shared/components/formComponents/ZqDatePicker';
import { dateFormate } from '@/utils/dateFormate';
import ZqSelectField from './components/ZqSelectField';
import ZqSelectAwards from './components/ZqSelectAwards';
import ZqSelectTransformerId from './components/ZqSelectTransformerId';
import ZqHostingOptionsFields from './ZqHostingOptionsFields';
import ZqSelectRewardTypeId from './components/ZqSelectRewardTypeId';
import ZqSelectWebhooksTriggers from './components/ZqSelectWebhooksTriggers';
import ZqSelectEntityRefIds from './components/ZqSelectEntityRefIds';
import ZqSelectRewardEntityType from './components/ZqSelectRewardEntityType';
import ZqSelectUnitOfMeasure from './components/ZqSelectUnitOfMeasure';
import ZqTextArray from './components/ZqTextArray';
import ClPageToolbar from "@/shared/components/ClPageToolbar";
import ZqInstantWinType from '@/shared/components/ZqInstantWinType.vue';

export default {
  name: 'FormBuilder',
  components: {
    ZqInstantWinType,
    ZqSelectWebhooksTriggers,
    ZqSelectRewardTypeId,
    ZqHostingOptionsFields,
    ZqSelectField,
    ZqSelectAwards,
    CreateHeader,
    SwitchRow,
    ZqDatePicker,
    ZqSelectTransformerId,
    ZqSelectEntityRefIds,
    ZqSelectRewardEntityType,
    ZqSelectUnitOfMeasure,
    ClPageToolbar,
    ZqTextArray,
  },
  props: {
    list: [Array],
    page: Object,
    isCreateHeader: {
      type: Boolean,
      default: true
    },
    isHideDisabled: {
      type: Boolean,
      default: false
    },
    isSettingsStep: {
      type: Boolean,
      default: false
    },
    stepNumber: {
      type: Number,
      default: 1
    },
    pageTitle: {
      type: String,
      default: 'Settings'
    },
    isAllFieldsDisabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      formList: null,
      obj: null,
      requiredText: 'Required',
      multipleFields: ['entityTypes', 'removeRoles', 'addRoles', 'teamMembers'],
      taggableFields: ['teamMembers'],
      isManualKey: false,
      isEditedKey: false,
      isToolbar: false,
    };
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    ...mapGetters('actionTypes', ['success', 'message', 'loading']),
    selectAppliesErrorClass() {
      const className =
        this.theme === 'main'
          ? 'error-validation--multiselect'
          : 'error-validation';
      return {
        [className]: this.appliesValidate === false,
      };
    },
  },
  created() {
    if (this.list) {
      this.formList = this.list;
    }

    this.formList.forEach(form => {
      if (form.key === 'postToUrl') {
        form.tooltip = this.page.info.postToURL
      }
    });

    if (this.formList['isManualKey']) {
      this.isManualKey = this.formList['isManualKey'] ? this.formList['isManualKey'] : false
    }
    if (this.formList['isEditedKey']) {
      this.isEditedKey = this.formList['isEditedKey'] ? this.formList['isEditedKey'] : false
    }
    if (this.isAllFieldsDisabled) {
      this.formList.forEach(form => {
        form.disabled = true;
      });
    }
  },
  watch: {
    formList: {
      deep: true,
      handler() {
        this.obj = {};
        this.formList.forEach((x) => {
          if (!x.ignore) {
            if (
                x.type.toLowerCase() !== 'namekey'
                && x.type.toLowerCase() !== 'hostingoptions'
                && x.type.toLowerCase() !== 'rewardentitytype'
            ) {
              this.obj[x.key] = x.value;
            } else {
              this.obj = {
                ...this.obj,
                ...x.value,
              };
            }
          }
        });
        this.$emit('updated', this.obj);
      },
    },
    list: {
      deep: true,
      handler(n, o) {
        if (!isEqual(o, n)) {
          this.formList = this.list;
        }
      },
    },
  },
  methods: {
    dateFormate: dateFormate,
    getLabel(form) {
      return form.required ? startCase(form.label) + '*' : startCase(form.label);
    },
    setIsManualKey() {
      this.isManualKey = true
      this.formList.isManualKey = true
    },
    setIsEditedKey() {
      this.formList.isEditedKey = true
    },
    handleToggleEditorToolbar(val) {
      this.isToolbar = val;
    },
  },
};
</script>

<style lang="scss">
.zq-invalid > .zq--form-row--content > .multiselect {
  border: solid 1px var(--zq-warn) !important;
}
.zq-invalid > .zq--form-row--content > .zq-required-message {
  display: block;
}
.c-nxt-app__main-theme .zq--multiselect .multiselect__option {
  white-space: normal;
}
</style>